import * as React from "react";
import { Helmet } from "react-helmet";

import Title from "../components/title";
import Subtitles from "../components/subtitles";

const HomePage = () => {
  const title = "Hello Foxy!";
  const subtitles = [
    "Thanks for wanting to hang out",
    "even if i'm a lil sad.",
    "Not many people do.",
    "It means a lot.",
    "Big hugs. ❤️‍🩹",
  ];

  return (
    <main className="font-body text-white bg-black">
      <Helmet>
        <title>{title}</title>
        <link
          href="https://fonts.googleapis.com/css?family=Codystar|Comfortaa:400,700|Monoton&display=swap"
          rel="stylesheet"
        />
        <link
          rel="icon"
          href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🌻</text></svg>"
        />
      </Helmet>

      <header className="min-h-screen p-6 text-center">
        <Title title={title} />
        <Subtitles subtitles={subtitles} className="pt-6" />
        <p className="pt-6">
          <span className="text-4xl px-1">🦊</span>
          <span className="text-4xl px-1">🌻</span>
        </p>
        <div className="opacity-0 absolute left-0 top-0 w-0 h-0">
          {/*          <iframe
            width="0"
            height="0"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1373743354&color=%23161921&auto_play=true&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true"
          />*/}
          <iframe
            width="0"
            height="0"
            src="https://www.youtube-nocookie.com/embed/vIkAb0aspO0?autoplay=1&loop=1&start=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          />
        </div>
      </header>
    </main>
  );
};

export default HomePage;
