import * as React from "react";

import { getRandomColor } from "../util/utils";

type Props = {
  title: string;
  className?: string;
};

const Title: React.FC<Props> = ({ title, className }) => {
  const characters = title.split("");

  const [state, setState] = React.useState({
    characterPos: 0,
    colors: characters.map((character) => "#FFFFFF"),
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      setState((state) => {
        // color the current character
        const nextColors = state.colors.map((color, i) => {
          return i == state.characterPos ? getRandomColor(75) : color;
        });

        // set the next character
        const nextCharacterPos = (state.characterPos + 1) % characters.length; // wave
        // const nextCharacterPos = Math.floor(Math.random() * characters.length) // random

        return {
          characterPos: nextCharacterPos,
          colors: nextColors,
        };
      });
    }, 250);

    return () => clearInterval(interval);
  }, []);

  return (
    <h1
      id="title"
      className={`font-title text-5xl lg:text-9xl uppercase ${className}`}
    >
      {characters.map((character, i) => {
        const color = state.colors[i];

        return (
          <span key={i} style={{ color: color }}>
            {character}
          </span>
        );
      })}
    </h1>
  );
};

export default Title;
