import * as React from "react";

import { getRandomColor } from "../util/utils";

type Props = {
  subtitles: string[];
  className?: string;
};

const Subtitles: React.FC<Props> = ({ subtitles, className }) => {
  const [subtitlePos, setSubtitlePos] = React.useState(subtitles.length);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSubtitlePos(
        (subtitlePos) => (subtitlePos + 1) % (subtitles.length + 1)
      );
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <p className={`font-subtitle text-4xl lg:text-7xl uppercase ${className}`}>
      {subtitles.map((subtitle, i) => {
        if (i == subtitlePos) {
          const color = getRandomColor(75);
          return (
            <span key={i} style={{ color: color }}>
              {subtitle}{" "}
            </span>
          );
        } else {
          return (
            <span key={i} className="opacity-0">
              {subtitle}{" "}
            </span>
          );
        }
      })}
    </p>
  );
};

export default Subtitles;
